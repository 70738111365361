#redirectSubMenuRow{background: #FFF;height: 150px;opacity: 0.9;z-index: 100;position: absolute;top: 83%;width: 100%;display: flex;align-items: center;}
#redirectSubMenuArea{display: flex;}
#redirectSubMenuBox{margin: auto;align-items: center;width: 150px;height: 100%;display: inline-grid;}
#redirectSubMenuBox > img {margin: auto;}
#redirectSubMenuBox > span {text-align: center;font-weight: bold;}

.notice_title > td{ text-align: center}

/* 2023.06.09 twkim 홈페이지 대시보드 버튼 CSS */
.applicationMenu{width: 100%; height: 120px; position: absolute; bottom:0px; z-index: 999; background:rgba(0, 0, 0, 0.4);}
.applicationMenu>ul{overflow: hidden; width: 760px; margin: 0 auto; height: 100%;}
.applicationMenu>ul>li{width: 147px; height: 100%; display: inline-block; float: left; text-align: center;}
.applicationMenu>ul>li:hover{ background-color: #f47725;}
.applicationMenu>ul>li>a{display: block; padding: 15% 0; cursor: pointer; text-decoration: none; color: #fff; font-weight: bold; letter-spacing: -1px; font-size: 16px;}
.applicationMenu>ul>li>a:hover,a:hover,a:active,a:visited{}
.applicationMenu>ul>li>span{ display: block;}

@media all and (max-width: 480px) {
  .applicationMenu{height: 100%; position: relative; z-index: 999; background:rgba(0, 0, 0, 0.4);}
  .applicationMenu>ul{width: 100%; height: auto;}
  .applicationMenu>ul>li{width: 49.5% !important; border-left:1px solid rgba(255, 255, 255, 1) !important; border-right:0px!important; border-bottom:1px solid rgba(255, 255, 255, 1) !important;}
}
@media all and (max-width: 768px) {
  .applicationMenu{height: 100%; position: relative; z-index: 999; background:rgba(0, 0, 0, 0.4);}
  .applicationMenu>ul{width: 100%; height: auto;}
  .applicationMenu>ul>li{width: 33.33%;}
}



#app {
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
