.loading-base{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 15px;
    opacity: 0.8;
    z-index: 900;
}

.loading-content{margin-top: 20%}
.loading-title{width:100%; margin-top: 12%;position: absolute}
.min-loading-content>div{
    color: #005AAB;
    width: 12%;
    height: 100%;
    display: flex;
    align-items: center;
}

#loading-position-absolute{position: absolute;}
#loading-position-fixed{position: fixed;}